
import Vue from 'vue';
import Component from 'vue-class-component'

@Component
export default class Login extends Vue {
  email = "";
  emailRules = [
    (v: string) => !!v || 'Email is required',
    (v: string) => /.+@.+\..+/.test(v) || 'Email must be valid',
  ]

  password = "";
  passwordRules = [
    (v: string) => !!v || 'Password is required',
    (v: string) => v.length > 8 || 'Password is too short',
    (v: string) => v.length < 64 || 'Password is too long'
  ];

  alert = false;
  errorMessage = "";

  loginAlert = false;

  $refs!: {
    form: HTMLFormElement
  }

  async validate() {
    if (this.$refs.form.validate()) {
      this.$users.login(
        this.email,
        this.password
      ).then(async (user) => {
        // Log the user in the store
        await this.$store.dispatch('login', user);

        // Redirect if required
        if (this.$route.params.nextUrl) {
          this.$router.push(this.$route.params.nextUrl);
        } else {
          this.$router.push({
            path: '/'
          });
        }
      }).catch(() => {
        this.loginAlert = true;
      });
    }
  }
}
